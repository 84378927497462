import axios1 from "axios"
//引入axios
import axios, {
    get,
    post
} from '../utils/request';


//引入接口地址
import base from './base'

const api = {
    getGuestBook(params) {
        return post(base.getGuestBook, params)
    },

    newsList(params) {
        return post(base.newsList, params)
    },

    newsDetail(params) {
        return get(base.newsDetail, params)
    }
}

export default api;
