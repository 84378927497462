import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'//element-ui
import './assets/css/base.css';
import api from './api' //网络请求

import globalVar from "@/globalVarible/index.js"
Vue.prototype.$globalVar=globalVar
Vue.prototype.$bus = new Vue()//获取全局属性
Vue.config.productionTip = false;

// 引入vant   
import './plugins/vant';
import 'swiper/css/swiper.min.css';


router.afterEach((to, from, next) => {

  window, scrollTo(0, 0)

})

//网络请求挂载Vue原型
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
