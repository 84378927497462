import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [{
        path: '/',
        name: 'Home',
        component: () => import('../views/Home/Home.vue')
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('../views/Product/index.vue'),
      },
      // 宠物系列
      {
        path: '/product-pet',
        component: () => import('../views/Product/pet.vue')
      },
      // 爱情系列
      {
        path: '/product-love',
        component: () => import('../views/Product/love.vue')
      },
      {
        path: '/journalism',
        name: 'Journalism',
        component: () => import('../views/Journalism/index.vue')
      },
      {
        path: '/newDetails',
        name: 'newDetails',
        component: () => import('../views/Journalism/newDetails.vue')
      },
      {
        path: '/dialogue',
        name: 'Dialogue',
        component: () => import('../views/Dialogue/index.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About/index.vue')
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  routes

})


export default router