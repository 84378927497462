
import Vue from 'vue';

import { Popup, Button } from 'vant';
import { Empty } from 'vant';


Vue.use(Popup );
Vue.use(Button);
Vue.use(Empty);





