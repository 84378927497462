//定义接口
// const api="http://117.78.6.235:8888"
// const api="http://192.168.1.20:8888"
// const api="http://117.78.6.235:8888"
// const api="http://192.168.1.20:9999"

import { product } from "../../staticData";


const base = {
    // getGuestBook: product + "/guest-book/save",  // 用户留言
    // newsList: product + '/news/selectAllNews',  // 科普趣味列表
    // newsDetail: product + '/news/findNewsById',  // 科普趣味详情
    getGuestBook: "/api/guest-book/save",  // 用户留言
    newsList:  '/api/news/selectAllNews',  // 科普趣味列表
    newsDetail: '/api/news/findNewsById',  // 科普趣味详情
}

export default base