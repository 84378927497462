import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
    Button,
    Carousel,
    CarouselItem,Input,Pagination
} from 'element-ui';
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Input)