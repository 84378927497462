<!--  -->
<template>
    <router-view></router-view>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>