<!--  -->
<template>
  <div class="footer">
    北京佰达康科技有限公司版权所有 | 京ICP备2021032350号-1
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@media all and (min-width: 1280px) {
  .footer {
    background-color: #2a2f37;
    width: 100%;
    height: 0.6rem;
    line-height:0.6rem;
    text-align: center;
    font-size:18px;
    color: #fff;
    font-weight: 400;
    position: absolute;
    bottom: 0;
  }
}

@media all and (min-width: 1024px) and (max-width: 1279px) {
  .footer {
    background-color: #2a2f37;
    width: 100%;
     height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    font-size: 0.13rem;
    color: #fff;
    font-weight: 400;
    position: absolute;
    bottom: 0;
  }
}

@media all and (min-width: 751px) and (max-width: 1023px) {
  .footer {
    background-color: #2a2f37;
    width: 100%;
      height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    font-size: 0.12rem;
    color: #fff;
    font-weight: 400;
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .footer {
    background-color: #2a2f37;
    width: 100%;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    font-size: 0.26rem;
    color: #fff;
    font-weight: 400;
    position: absolute;
    bottom: -0.7rem;
  }
}
</style>