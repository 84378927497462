export default {
  menu3: "活动趣闻",
  menu3_desc1: "ACTIVITY",
  menu3_desc2: "ANECDOTE",
  about_desc:
    "「我和你之间EM DNA UOY」。将我们品牌的英文简称颠倒过来为“YOU AND ME”，寓意着基因是连接你我之间的桥梁，让爱在我和你之间发生。我们相信，通过基因测序的方式，可以更好地理解生命的意义和价值，进一步加深人与人/人与动物间的链接，增强我们的幸福感。",
  about_desc_about_page: `"我和你之间EM DNA UOY"——当我们将英文缩写颠倒过来时，就变成了“YOU AND ME”，寓意着基因是我与你间相互连接的桥梁，是让爱在我们之间流动的纽带。我们坚信，通过基因测序这个深入探索生命奥秘的工具，我们可以更深入地理解生命的真谛和价值，进一步增强我们与他人，甚至与动物之间的联系，使我们的生活更加充实和满足。
   `,
   about_desc_about_page2:" 我们的团队由一群热爱生命科学、致力于推动人类进步的小伙伴组成。我们不断探索、创新，以科技为工具，希望创作出更多有新意的基因检测产品，摆脱传统基因产品的束缚感、距离感，将基因产品融入进生活中。我们相信，通过我们的努力，可以帮助每个人更好地理解自己和他人的生命，进一步增强人与人之间的理解和联系，让更多的爱，在我和你之间流动。",
   
    menu2_c_title:"宠物系列",
    menu2_c_title_en1:"PET",
    menu2_c_title_en2:"SERIES",
    menu2_c_1:"宠物性格基因检测",
    menu2_c_1_desc:"PET PERSONALJTY GENETIC TESTING",
    menu2_c_1_content:`狗狗的那些奇怪的行为，比如无理由的吠叫、撕咬家具的原因，见到陌生人总是回避...多次训练也得不到缓解，而这些问题及疑惑都可以从狗狗的性格中去了解，发现。狗狗十六型性格基因检测弥补了人与狗狗间的信息屏障，了解狗狗不再单单通过观察和训练，而是通过科学可信的基因检测技术，了解狗狗天生的性格偏 向，进而读懂狗狗的行为，增进养宠者与宠物间的亲密关系，给狗狗需要的关心与爱。`,

    menu2_c_title2:"情侣性格适配基因检测",
    menu2_c_2_desc:"COUPLES HAVE A GOOD PERSONALITY",
    menu2_c_2_content:"爱情是人类永恒的主题，它单纯而又复杂，从相识到相爱，再到白头偕老，你了解他多少呢？通过基因测序了解他不同维度的表现，涵盖吃货水平、语言表达、共情能力等12种不同的维度。了解生命最初的他，认识最纯粹的他，让书信给你解读，让基因给予答案。",

    menu2_c_2:"爱情系列",
    menu2_c_2_title_en1:"LOVE",
    menu2_c_2_title_en2:"SERIES",
    menu2_c_2_desc:"爱情是人类永恒的主题，它单纯而又复杂，从相识到相爱，再到白头偕老，你了解他多少呢？通过基因测序了解他不同维度的表现，涵盖吃货水平、语言表达、共情能力等12种不同的维度。了解生命最初的他，认识最纯粹的他，让书信给你解读，让基因给予答案。",
};
