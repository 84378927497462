<!--  -->
<template>
  <div class="layout">

    <!-- 内容 -->
    <Content class="mainContent" />
    <!-- 页脚 -->
    <Footer/>
  </div>
</template>

<script>
import Content from "./Content/index.vue";
import Footer from '../../components/footer.vue';
export default {
  components: { Content ,Footer},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.layout{
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  @media (min-width: 320px) and (max-width: 750px) {
    .mainContent {
      padding-bottom: 0.5rem;
    }
  }
  @media (min-width: 751px) and (max-width: 1023px) {
    .mainContent {
      padding-bottom: 0.6rem;
    }
  }
  @media (min-width: 1023px) {
    .mainContent {
      padding-bottom: 0.6rem;
    }
  }
  
}
</style>