<template>
  <div id="app">
    <router-view />
    <div class="top iconfont icon-icon_xinyong_xianxing_jijin-146" v-if="scrollTag" @click="goTop">
    
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTag: false,
    };
  },
  updated() {
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top > 500) {
        this.scrollTag = true;
      } else {
        this.scrollTag = false;
      }
    });
  },
  methods: {
    goTop() {
      let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置
      // 定时器平滑滚动
      const time = setInterval(() => {
        document.documentElement.scrollTop = scrolltop -= 40;
        if (scrolltop <= 0) {
          // 定时器要及时清除掉，要不然一直执行很恐怖的
          clearInterval(time);
        }
      }, 10);
    },
  },
};
</script>
<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  .top {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 70px;
    right: 20px;
    color: #ffffff;
    background-color: #CC9966;
    border-radius: 10px;
    padding: 3px;
    text-align: center;
    z-index: 999;
    line-height: 50px;
    font-size: 24px;
  }
  @media screen and (min-width: 320px) and (max-width: 750px) {
    .top {
      display: none;
    }
  }
}
</style>
